import ReactMarkdown from 'react-markdown';

type ReactMarkdownProps = React.ComponentProps<typeof ReactMarkdown>;

import classNames from 'classnames';
import { Link } from 'modules/i18n';
import React from 'react';

type MarkdownProps = ReactMarkdownProps & {
  // TODO
};

const components: ReactMarkdownProps['components'] = {
  a: ({ children, href }) => {
    if (href?.startsWith('/')) {
      return <Link href={href}>{children}</Link>;
    }
    return (
      <a href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  },
};

const Markdown: React.FC<MarkdownProps> = ({ children, className, ...props }) => {
  return (
    <ReactMarkdown className={classNames('', className)} components={components} {...props}>
      {children}
    </ReactMarkdown>
  );
};

export default Markdown;
