import { atcb_action } from 'add-to-calendar-button';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Markdown from 'lib/components/ui/markdown';
import { kebabCase } from 'lodash/fp';
import { event as analyticEvent } from 'modules/Analytics';
import { getColorAndLabel } from 'modules/Marketing/components/ToolBadge';
import useUser from 'modules/User/hooks/useUser';
import Price from 'modules/common/components/Price';
import Tooltip from 'modules/common/components/Tooltip';
import usePublicRuntimeConfig from 'modules/common/hooks/usePublicRuntimeConfig';
import dynamic from 'next/dynamic';
import React from 'react';
import { MdCalendarViewMonth, MdClose } from 'react-icons/md';
const Calendar = dynamic(() => import('modules/common/components/Calendar'), { ssr: false });
type EventCalendarProps = React.HTMLAttributes<HTMLDivElement> & {
  // TODO
};

import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const dayjsWithTimezone = (whatever?: Parameters<typeof dayjs>[0]) => dayjs(whatever).tz('Europe/Paris');

const COLD_CALL_SESSION_URL = 'https://buy.stripe.com/bIYbKagL5668836eV2';
const COLD_CALL_URL = 'https://buy.stripe.com/dR66pQ7av3Y0fvyeUX';

const coldCall = {
  id: 'cold-call',
  title: 'Atelier "Cold Call"',
  author: 'Pierre Ardellier',
  authorUrl: 'https://www.linkedin.com/in/pierre-ardellier/',
  pricePro: 0,
  buyProUrl: 'https://wa.me/+33632791518',
  priceStandard: 90,
  buyStandardUrl: COLD_CALL_URL,
  type: 'workshop',
  details: `**Thème atelier**: 
Pierre Ardellier te coache pendant 3h en analysant tes calls.

**Durée**: 
3h

**L'atelier**
- Rappel des bases théoriques de la prospection
- Réponse aux objections courantes`,
};

const coldCallVanessa = {
  id: 'cold-call',
  title: 'Atelier "Cold Call"',
  author: 'Vanessa Amsili',
  authorUrl: 'https://www.linkedin.com/in/vanessa-amsili',
  pricePro: 0,
  buyProUrl: 'https://wa.me/+33632791518',
  priceStandard: 90,
  buyStandardUrl: COLD_CALL_URL,
  type: 'workshop',
  details: `**Thème atelier**: 
Vanessa Amsili te coache pendant 3h en analysant tes calls.

**Durée**: 
3h

**L'atelier**
- Rappel des bases théoriques de la prospection
- Réponse aux objections courantes`,
};
const coldCallSession = {
  id: 'cold-call-session',
  title: 'Session "Cold Call"',
  author: 'Dorothée de Calan',
  authorUrl: 'https://www.linkedin.com/in/dorothee-de-calan/',
  pricePro: 0,
  buyProUrl: 'https://wa.me/+33632791518',
  priceStandard: 20,
  buyStandardUrl: COLD_CALL_SESSION_URL,
  type: 'session',
  url: 'https://meet.google.com/iju-rxdf-mxx',
  details: `**Thème session**: 
Arrête la procrastination et vient passer tes appels en binôme avec un autre recruteur

Check le [détail de la session](/tools/sessions-cold-call.pdf)

**Durée**: 
3h`,
};

const prospectionList = {
  id: 'prospection-list',
  title: 'Atelier "Création Liste prospection"',
  author: 'Dimitri Cayrou',
  authorUrl: 'https://www.linkedin.com/in/dimitricayrou/',
  pricePro: 0,
  buyProUrl: 'https://wa.me/+33632791518',
  priceStandard: 60,
  buyStandardUrl: 'https://buy.stripe.com/dR69C21Qbcuwdnq3cg',
  type: 'workshop',
  details: `**Thème atelier**: 
Créer une liste de prospection ultra qualifiée
**Durée**: 
2h

**Prérequis**
Installer l'outil Pharow à l'aide du code de Dimitri. 

**L'atelier**
1h de théorie (rappel des règles de ciblage)
1h de passage à l'action pour que tu ressortes avec une liste de prospection propre.`,
};

const copywriting = {
  id: 'copywriting',
  title: 'Atelier "maîtriser le copywriting pour rédiger des messages de prospection qui convertissent',
  author: 'Dimitri Cayrou',
  authorUrl: 'https://www.linkedin.com/in/dimitricayrou/',
  pricePro: 0,
  buyProUrl: 'https://wa.me/+33632791518',
  priceStandard: 60,
  buyStandardUrl: 'https://buy.stripe.com/3cscOe3Yjamoeru8wD',
  type: 'workshop',
  details: `**Thème atelier**: 
Maîtriser le copywriting pour enfin obtenir des réponses à ses messages de prospection. 

**Durée**: 
2h

**L'atelier**
1h de théorie (rappel des règles de copywriting)
1h de passage à l'action pour que tu ressortes avec des messages de prospection prêts à être envoyés`,
};

// const mindset = {
//   title: 'Atelier "Cold Call"',
//   author: 'Joanna Bouy',
//   authorUrl: 'https://www.linkedin.com/in/joanna-bouy',
//   pricePro: 35,
//   buyProUrl: 'https://buy.stripe.com/dR615w52ncuw2IM9AC',
//   priceStandard: 50,
//   buyStandardUrl: 'https://buy.stripe.com/aEU3dE0M70LOcjmbIJ',
//   type: 'atelier',
//   details: `
// **Thème atelier**:
// Mindset du recruteur indépendant avec son client: mettre ses limites, appréhender la négociation

// **Durée**:
// Environ 1H30

// **Mode opératoire**:
// Survey en amont pour te demander ce qui te pose le plus de soucis, afin de coller au plus proche de tes besoins
// Création du contenu
// Animation
// Suivi

// **Déroulé atelier**:
// - Introduction: Présentation de l'objectif de l'atelier : développer un mindset positif et confiant pour mieux gérer les relations avec les clients.
// - Explication de l'importance du mindset et des conséquences "concrètes" +lecture des résultats du sondage
// - Comprendre les blocages (peur de perdre un client, syndrome de l'imposteur, manque de confiance en soi, manque de technique pour dire non)
// - Cas concrets: à partir cas précis (négociations tarifaires, demandes de clients déraisonnables, etc.), on fait réfléchir les participants à comment on pourrait l'adresser, puis on donne des solutions concrètes
// - Solutions boîte à outils concrète pour répondre aux objections (expertise et marque, assertivité)
// - Conclusion et plan d'action personnel: les participants s'engagent à tester une technique dans leur quotidien
// - Échanges et tour de table
// `,
// };

const presentationSkillie = {
  id: 'skillie',
  title: 'Présentation "Skillie"',
  author: 'Dorothée',
  authorUrl: 'https://www.linkedin.com/in/dorothee-de-calan/',
  url: 'https://meet.google.com/kkp-sgst-boq',
  pricePro: 0,
  buyProUrl: null,
  priceStandard: 0,
  buyStandardUrl: null,
  type: 'presentation',
  details: `
  
`,
};

const coffee = {
  id: 'coffee',
  title: 'Café Headlinker',
  author: 'Dorothée',
  authorUrl: 'https://www.linkedin.com/in/dorothee-de-calan/',
  url: 'https://gobrunch.com/events/land/372616',
  pricePro: 0,
  buyProUrl: null,
  priceStandard: 0,
  buyStandardUrl: null,
  type: 'coffee',
  details: `![](/images/events/coffee.png)`,
};

const apero = {
  id: 'apero',
  title: 'Apéro Headlinker',
  author: 'Dorothée',
  authorUrl: 'https://www.linkedin.com/in/dorothee-de-calan/',
  pricePro: 0,
  buyProUrl: null,
  priceStandard: 0,
  buyStandardUrl: null,
  type: 'apero  ',
  details: `Chez Headlinker, les pauses café sont virtuelles, mais les apéros sont en présentiel :) Viens trinquer avec nous à la santé du recrutement !`,
};

const presentationTrimoji = {
  id: 'trimoji',
  title: 'Présentation "Trimoji"',
  author: 'Davy Correia',
  authorUrl: 'https://www.linkedin.com/in/davy-correia-9b01061b9/',
  url: 'https://app.livestorm.co/p/77cc501d-aa27-4e40-a11d-3bebec5f8a89/live?s=0d90909b-44d9-44f0-9ae6-f8111a1e614d',
  pricePro: 0,
  buyProUrl: null,
  priceStandard: 0,
  buyStandardUrl: null,
  type: 'presentation',
  details: `**Thème atelier**: 
Se démarquer de la concurrence et augmenter ses prix grâce à Trimoji

**Durée**: 
1h

**Mode opératoire**
Sondage en amont pour identifier ton niveau de difficulté à te positionner face à la concurrence, à justifier un princing élevé, à être identifié comme un acteur différenciant par tes candidats. 
Pendant l'atelier : présentation des usages de l'outil, témoignages et questions/réponses sur le fonctionnement de l'outil.

**Prendre directement rendez-vous avec Davy**
[Voir le calendrier](https://calendly.com/davy-correia/rendez-vous-decouverte-trimoji-x-headlinker)
`,
};

const masterclass = {
  id: 'masterclass',
  title: '3 méthodes pour trouver ses clients',
  author: 'Kemra Jaafar',
  authorUrl: 'https://www.linkedin.com/in/kemra-jaafar-4644b8173/',
  url: 'https://app.livestorm.co/recrutech-co/trouver-des-clients-pour-les-recruteurs-independants?s=a5033b2d-d188-43b9-a3a1-13fdc3cf469d',
  pricePro: 0,
  buyProUrl: null,
  priceStandard: 0,
  buyStandardUrl: null,
  type: 'presentation',
  details: `
# Les 3 meilleures méthodes pour trouver ses clients
  
Si toi aussi :

- tu n'as pas de méthode de prospection
- tu ne réussis pas à décrocher des RDV avec des RH ou des opérationnels
- tu n'aimes pas prospecter
- ton offre ressemble à toutes celles des cabinets et de tes confrères indépendants (% du SBA ou TJM)

Je t'invite à rejoindre cette masterclass. On va te donner 3 méthodes efficaces pour trouver tes clients.

Promesse :

- 0 bullshit
- 3 méthodes, 100% actionnables
- \\+ 1 bonus si tu restes jusqu'à la fin de la Masterclass :

\\+ PDF du live
  
\\+ 1 accès Notion avec des exemples d'offres (vraiment!) différenciantes
  
`,
};

export const events = [
  {
    ...coldCall,
    start: dayjsWithTimezone('2024-05-16T09:00:00').toDate(),
    end: dayjsWithTimezone('2024-05-16T12:00:00').toDate(),
    duration: '3h',
    priceStandard: 60,
    buyStandardUrl: 'https://buy.stripe.com/eVa6pQ66r3Y0abefYY',
  },
  {
    ...coldCall,
    start: dayjsWithTimezone('2024-06-06T15:00:00').toDate(),
    end: dayjsWithTimezone('2024-06-06T18:00:00').toDate(),
    duration: '3h',
    priceStandard: 60,
    buyStandardUrl: 'https://buy.stripe.com/eVa6pQ66r3Y0abefYY',
  },
  {
    ...presentationSkillie,
    start: dayjsWithTimezone('2024-05-21T11:00:00').toDate(),
    end: dayjsWithTimezone('2024-05-21T12:00:00').toDate(),
    duration: '1h',
  },
  {
    ...presentationTrimoji,
    start: dayjsWithTimezone('2024-05-28T11:00:00').toDate(),
    end: dayjsWithTimezone('2024-05-28T12:00:00').toDate(),
    duration: '1h',
  },
  ...Array.from({ length: 6 }).map((_, i) => ({
    ...coffee,
    start: dayjsWithTimezone().add(i, 'week').day(5).hour(12).minute(0).second(0).toDate(), // Next 6 Fridays at 12:00 AM
    end: dayjsWithTimezone().add(i, 'week').day(5).hour(12).minute(30).second(0).toDate(), // Ends at 12:30 AM
    duration: '1h',
  })),
  {
    ...prospectionList,
    start: dayjsWithTimezone('2024-06-11T10:00:00').toDate(),
    end: dayjsWithTimezone('2024-06-11T12:00:00').toDate(),
    duration: '2h',
  },
  {
    ...copywriting,
    start: dayjsWithTimezone('2024-06-28T14:30:00').toDate(),
    end: dayjsWithTimezone('2024-06-28T16:30:00').toDate(),
    duration: '2h',
  },
  {
    ...masterclass,
    start: dayjsWithTimezone('2024-06-24T12:00:00').toDate(),
    end: dayjsWithTimezone('2024-06-24T13:00:00').toDate(),
    duration: '2h',
  },
  {
    ...masterclass,
    start: dayjsWithTimezone('2024-06-28T12:00:00').toDate(),
    end: dayjsWithTimezone('2024-06-28T13:00:00').toDate(),
    duration: '2h',
  },
  // {
  //   ...apero,
  //   title: `${apero.title} Lyon`,
  //   start: dayjsWithTimezone('2024-06-06T19:00:00').toDate(),
  //   end: dayjsWithTimezone('2024-06-06T23:00:00').toDate(),
  //   duration: '',
  // },
  {
    ...apero,
    title: `${apero.title} Paris`,
    start: dayjsWithTimezone('2024-05-30T19:00:00').toDate(),
    end: dayjsWithTimezone('2024-05-30T23:00:00').toDate(),
    duration: '',
  },
  {
    ...coldCall,
    start: dayjsWithTimezone('2024-09-17T09:00:00').toDate(),
    end: dayjsWithTimezone('2024-09-17T12:00:00').toDate(),
    duration: '3h',
  },
  {
    ...coldCall,
    start: dayjsWithTimezone('2024-10-15T09:00:00').toDate(),
    end: dayjsWithTimezone('2024-10-15T12:00:00').toDate(),
    duration: '3h',
  },
  {
    ...coldCallSession,
    title: 'Session Cold Call',
    start: dayjsWithTimezone('2024-09-10T09:00:00').toDate(),
    end: dayjsWithTimezone('2024-09-10T12:00:00').toDate(),
    duration: '3h',
  },
  {
    ...coldCallSession,
    title: 'Session Cold Call',
    start: dayjsWithTimezone('2024-09-24T09:00:00').toDate(),
    end: dayjsWithTimezone('2024-09-24T12:00:00').toDate(),
    duration: '3h',
  },
  {
    ...coldCallSession,
    title: 'Session Cold Call',
    start: dayjsWithTimezone('2024-10-01T09:00:00').toDate(),
    end: dayjsWithTimezone('2024-10-01T12:00:00').toDate(),
    duration: '3h',
  },
  {
    ...coldCallSession,
    title: 'Session Cold Call',
    start: dayjsWithTimezone('2024-10-08T09:00:00').toDate(),
    end: dayjsWithTimezone('2024-10-08T12:00:00').toDate(),
    duration: '3h',
  },
  {
    ...presentationTrimoji,
    start: dayjsWithTimezone('2024-11-07T11:30:00').toDate(),
    end: dayjsWithTimezone('2024-11-07T12:30:00').toDate(),
    duration: '1h',
  },
  {
    ...coldCallSession,
    title: 'Session Cold Call',
    start: dayjsWithTimezone('2024-10-22T09:00:00').toDate(),
    end: dayjsWithTimezone('2024-10-22T12:00:00').toDate(),
    duration: '3h',
  },
  {
    ...coldCallSession,
    title: 'Session Cold Call',
    start: dayjsWithTimezone('2024-11-05T09:00:00').toDate(),
    end: dayjsWithTimezone('2024-11-05T12:00:00').toDate(),
    duration: '3h',
  },
  {
    ...coldCallVanessa,
    start: dayjsWithTimezone('2024-11-26T09:00:00').toDate(),
    end: dayjsWithTimezone('2024-11-26T12:00:00').toDate(),
    duration: '3h',
  },
  {
    ...coldCallSession,
    title: 'Session Cold Call',
    start: dayjsWithTimezone('2024-12-03T09:00:00').toDate(),
    end: dayjsWithTimezone('2024-12-03T12:00:00').toDate(),
    duration: '3h',
  },
];

const EventCalendar: React.FC<EventCalendarProps> = ({ className, ...props }) => {
  const { hasPermission } = useUser();
  const { whatsappDorothee } = usePublicRuntimeConfig();

  return (
    <div className={classNames('', className)} {...props}>
      <Calendar
        events={events}
        className="min-h-[80vh]"
        showAllEvents
        components={{
          event: ({ event }: any) => {
            const { id, start, end, title, author, authorUrl, details, duration, pricePro, priceStandard, buyProUrl, buyStandardUrl } =
              event as typeof events[number];
            const isPast = dayjs(start).isBefore(dayjs());
            return (
              <Tooltip
                placement="top"
                showArrow
                trigger={['click']}
                overlay={
                  <div className="calendar-popover">
                    <header>
                      <button
                        onClick={() => {
                          const tooltips = document.querySelectorAll('.rc-tooltip') as unknown as HTMLElement[];
                          tooltips.forEach((tooltip) => {
                            tooltip.style.display = 'none';
                          });
                        }}
                      >
                        <MdClose />
                      </button>
                    </header>
                    <h2>{title}</h2>
                    <a href={authorUrl} className="block text-gray-700 link" target="_blank" rel="noopener noreferrer">
                      {author}
                    </a>
                    <strong className="text-faded">{dayjs(start).format('dddd ll')}</strong>
                    <small className="text-faded italic inline-block ml-2">
                      {dayjs(start).format('HH[h]mm')} {end && dayjs(end).format('HH[h]mm')}
                    </small>
                    <div className="prose prose-sm mt-5">
                      <Markdown>{details}</Markdown>
                    </div>
                    <footer className="flex gap-2">
                      {!isPast && (
                        <button
                          className="btn btn-xl btn-white w-[280px] sm:w-auto"
                          onClick={() => {
                            analyticEvent({ action: `event-add-to-calendar-${id}`, category: 'calendar', label: id });
                            return atcb_action({
                              name: title,
                              description: details || '',
                              startDate: dayjs(start).format('YYYY-MM-DD'),
                              startTime: dayjs(start).format('HH:mm'),
                              endTime: dayjs(end).format('HH:mm'),
                              options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'MicrosoftTeams', 'Outlook.com', 'Yahoo'],
                              forceOverlay: true,
                              language: 'fr',
                              timeZone: 'Europe/Paris',
                              iCalFileName: kebabCase(title || ''),
                              location: event?.url || window.location.href,
                            });
                          }}
                        >
                          <MdCalendarViewMonth />
                          Ajouter au calendrier
                        </button>
                      )}
                      {!buyStandardUrl ? (
                        <a
                          className="btn btn-primary btn-xs px-3 py-2 h-12"
                          href={event?.url || whatsappDorothee}
                          target="_blank"
                          onClick={() => analyticEvent({ action: `event-participate-${id}`, category: 'calendar', label: id })}
                          rel="noopener noreferrer"
                        >
                          Participer
                        </a>
                      ) : (
                        <>
                          {buyStandardUrl && (
                            <a
                              className={classNames('btn btn-secondary btn-xs px-3 py-2 h-12 flex items-center gap-2', {
                                'line-through cursor-not-allowed btn-disabled': hasPermission('pro'),
                              })}
                              onClick={() => analyticEvent({ action: `event-buy-standard-${id}`, category: 'calendar', label: id })}
                              href={buyStandardUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {priceStandard === 0 ? 'Gratuit' : <Price value={priceStandard} currency="EUR" className="text-lg font-bold"></Price>}
                              {priceStandard === pricePro ? <span className="badge badge-sm">PROMO</span> : null}
                            </a>
                          )}
                          {buyProUrl &&
                            (hasPermission('pro') ? (
                              <a
                                className="btn btn-primary btn-xs px-3 py-2 h-12"
                                href={buyProUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => analyticEvent({ action: `event-get-pro-free-${id}`, category: 'calendar', label: id })}
                              >
                                {pricePro === 0 ? (
                                  <span className="text-lg font-bold">Gratuit</span>
                                ) : (
                                  <Price value={pricePro} currency="EUR" className="text-lg font-bold"></Price>
                                )}
                                Pro
                              </a>
                            ) : (
                              <a
                                className="btn btn-primary btn-xs px-3 py-2 h-12 flex items-center gap-2"
                                href={whatsappDorothee}
                                target="_blank"
                                onClick={() => analyticEvent({ action: `event-buy-pro-${id}`, category: 'calendar', label: id })}
                                rel="noopener noreferrer"
                              >
                                {pricePro === 0 ? (
                                  <span className="text-lg font-bold">Gratuit</span>
                                ) : (
                                  <Price value={pricePro} currency="EUR" className="text-lg font-bold"></Price>
                                )}
                                <div className="flex flex-col gap-1">
                                  {pricePro < priceStandard && (
                                    <span className="badge badge-sm badge-primary">
                                      <Price value={pricePro - priceStandard} currency="EUR" className=""></Price>
                                    </span>
                                  )}
                                  <span>Passer Pro</span>
                                </div>
                              </a>
                            ))}
                        </>
                      )}
                    </footer>
                  </div>
                }
              >
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    const tooltips = document.querySelectorAll('.rc-tooltip') as unknown as HTMLElement[];
                    tooltips.forEach((tooltip) => {
                      tooltip.style.display = 'none';
                    });
                  }}
                >
                  <div className="flex items-center gap-2">{title}</div>
                  <small className="flex items-center justify-between gap-2">
                    <span>{dayjs(start).format('HH:mm')}</span>
                    <span className="font-bold">{duration}</span>
                  </small>
                </div>
              </Tooltip>
            );
          },
        }}
        dayPropGetter={(date) => {
          const today = dayjs().startOf('day');
          return {
            className: dayjs(date).isBefore(today) && !dayjs(date).isSame(today, 'day') ? 'rbc-past-day bg-gray-100' : '',
          };
        }}
        eventPropGetter={({ type, start }: any) => {
          return {
            className: classNames(getColorAndLabel(type).color, {
              'opacity-50': dayjs(start).isBefore(dayjs()) && !dayjs(start).isSame(dayjs(), 'day'),
            }),
          };
        }}
      />
    </div>
  );
};

export default EventCalendar;
