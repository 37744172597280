'use client';

import classNames from 'classnames';

export type ToolType = 'tool' | 'workshop' | 'session' | 'training' | 'presentation' | 'coffee' | 'apero';

export const getColorAndLabel = (type?: ToolType) => {
  let label = '';
  let color = '!bg-primary textd-white';

  if (type === 'tool') {
    label = 'Outil';
    color = '!bg-pink-400 textd-white';
  } else if (type === 'workshop') {
    label = 'Atelier';
    color = '!bg-pink-500 textd-white';
  } else if (type === 'session') {
    label = 'Atelier';
    color = '!bg-pink-600 textd-white';
  } else if (type === 'training') {
    label = 'Formation';
    color = '!bg-pink-700 textd-white';
  } else if (type === 'presentation') {
    label = 'Présentation';
    color = '!bg-pink-400 textd-white';
  } else if (type === 'coffee') {
    label = 'Café';
    color = '!bg-primary textd-white';
  } else if (type === 'apero') {
    label = 'Apéro';
    color = '!bg-pink-200 textd-white';
  }

  return { label, color };
};

export default function ToolBadge({ type, className }: { type?: ToolType; className?: string }) {
  const { color, label } = getColorAndLabel(type);

  return <span className={classNames('badge font-bold', color || 'badge-ghost', className)}>{label || type}</span>;
}
