export const formatPrice = ({ value, currency = 'USD', options = {} }: any) => {
  const lang = 'en-US';

  if (value === undefined || value === null || Math.abs(value) === Infinity || isNaN(value)) {
    return '-';
  }

  const allOptions: any = {
    style: 'currency',
    currency,
    ...options,
  };

  if (value <= 0.01 && value >= -0.01) {
    // In cas value is 0, we display no decimals
    allOptions.minimumFractionDigits = 0;
    allOptions.maximumFractionDigits = 0;
  }

  try {
    return new Intl.NumberFormat(lang, allOptions).format(value).replace('US', '');
  } catch (err) {
    console.error(`Error formatting price with value ${value} and currency ${currency} for lang ${lang}`);
    console.error(err);
    return 'NaN';
  }
};

interface IProps {
  value: number;
  currency?: string;
  options?: Intl.NumberFormatOptions;
  className?: string;
}

const Price = ({ value, currency = 'USD', options, ...props }: IProps) => {
  const priceStr = formatPrice({ value, currency, options });

  return <span {...props}>{priceStr}</span>;
};

export default Price;
